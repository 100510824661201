<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <template>
          <v-data-table
            dense
            :headers="headers"
            :items="compUsuGruLista"
            sortBy="id"
            class="elevation-10"
            :footer-props="{
              'items-per-page-options': [20],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-icon class="mr-2" color="primary"
                  >mdi-account-outline</v-icon
                >
                <v-toolbar-title>Grupos do Usuário</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="blue darken-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="metCarregaLista"
                    >
                      mdi-refresh-circle
                    </v-icon>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="teal darken-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialog1 = true"
                    >
                      mdi-plus-circle
                    </v-icon>
                  </template>
                  <span>Inserir Grupo</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="indigo"
                      v-bind="attrs"
                      v-on="on"
                      @click="metVoltar()"
                    >
                      mdi-arrow-left-circle
                    </v-icon>
                  </template>
                  <span>Voltar</span>
                </v-tooltip> -->
              </v-toolbar>

              <v-container>
                <v-row dense> </v-row>
              </v-container>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    class="mr-2"
                    color="red darken-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="metDeleteItem(item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Deletar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.idGrupo`]="{ item }">
              <span>{{ metObterGrupo(item.idGrupo) }} </span>
            </template>
          </v-data-table>
          <v-dialog
            v-if="dail1"
            v-model="dialog1"
            min-width="300px"
            max-width="400px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Novo Grupo</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="auto" md="10" align-self="center">
                      <v-autocomplete
                        hide-details="auto"
                        v-model="usuGruLocal.idGrupo"
                        label="Grupo"
                        :items="compGrupoSelect"
                        item-text="grupo"
                        item-value="id"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  dense
                  class="mb-4 white--text"
                  color="green darken-2"
                  @click="metSalvar"
                >
                  Salvar
                </v-btn>
                <v-btn
                  dense
                  class="ml-2 mb-4 white--text"
                  color="blue darken-1"
                  @click="metFecharDialog1"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" min-width="400px" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Deletar grupo?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="ml-2 mb-4 white--text"
                  color="red darken-1"
                  @click="metDeleteConfirm"
                >
                  Deletar
                </v-btn>
                <v-btn
                  class="ml-2 mb-4 white--text"
                  color="blue darken-1"
                  @click="metFecharDelete"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";

const { mapState, mapActions } = createNamespacedHelpers("adminUsuarios");

export default {
  // mixins: [],
  name: "AdminUsuariosGruposLista",
  data: () => ({
    listaUsuGruAtivos: undefined,
    headers: [
      { text: "Código", value: "id", sortable: false },
      {
        text: "Grupo",
        value: "idGrupo",
        align: "left",
        sortable: false,
      },

      { text: "Ações", value: "actions", sortable: false },
    ],
    dail1: true,
    dialog1: false,
    dialogDelete: false,
    usuGruLocal: { id: null },
    usuGruLocalDefault: { id: null },
    usuGruDel: { id: null },
  }),

  computed: {
    ...mapState([
      "erro",
      "staUsuariosGrupos",
      "staUsuarioSelecionado",
      "staGruposSelect",
    ]),

    compUsuGruLista() {
      return this.staUsuariosGrupos;
    },

    compGrupoSelect() {
      return this.staGruposSelect;
    },
  },

  created() {
    register(this.$store);
    this.actResetarUsuariosGrupos();
    this.listaUsuGruAtivos = 2;
    this.metCarregaLista();
    this.actSelectGrupos();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actListarUsuariosGrupos",
      "actAtivarUsuGru",
      "actResetarUsuariosGrupos",
      "actSelectGrupos",
      "actCriarUsuariosGrupos",
      "actDeletarUsuariosGrupos",
    ]),

    metObterGrupo(id) {
      if (id != null && this.staGruposSelect.length != 0) {
        const ret = this.staGruposSelect.filter((c) => c.id == id);
        return ret[0].grupo;
      } else {
        const ret = "";
        return ret;
      }
    },

    async metSalvar() {
      const procura = this.staUsuariosGrupos.find(
        (t) => t.idGrupo == this.usuGruLocal.idGrupo
      );
      if (procura != undefined) {
        this.$root.snackbar.show({
          type: "warning",
          message: "Já existe no usuário!",
        });
        this.metFecharDialog1();
        return;
      }

      this.usuGruLocal.idUsuario = this.staUsuarioSelecionado.id;
      await this.actCriarUsuariosGrupos({
        usuGru: this.usuGruLocal,
        licenca: this.$store.state.login.licenca,
      });

      this.$root.snackbar.show({
        type: "success",
        message: "Módulo Inserido!",
      });
      this.metFecharDialog1();
    },

    metDeleteItem(item) {
      this.usuGruDel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    metFecharDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.usuGruLocal = Object.assign({}, this.usuGruLocalDefault);
      });
    },

    async metDeleteConfirm() {
      if (this.usuGruDel) {
        await this.actDeletarUsuariosGrupos({
          usuGru: JSON.parse(JSON.stringify(this.usuGruDel)),
          licenca: this.$store.state.login.licenca,
        });
        this.metFecharDelete();
      }
    },

    metFecharDialog1() {
      this.$nextTick(() => {
        this.usuGruLocal = Object.assign({}, this.usuGruLocalDefault);
      });
      this.dialog1 = false;
      this.dail1 = false;
      this.$nextTick(() => {
        this.dail1 = true;
      });
    },

    // metVoltar() {
    //   this.$router.back();
    // },

    login() {
      this.$router.push({ name: "Login" });
    },

    async metCarregaLista() {
      if (this.staUsuarioSelecionado != undefined) {
        const usuario = this.staUsuarioSelecionado;
        await this.actListarUsuariosGrupos({
          idUsuario: usuario.id,
          licenca: this.$store.state.login.licenca,
        });
      }
    },
  },
};
</script>
